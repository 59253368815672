<template>
  <div class="video-edit">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dashboard/video' }">视频管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加视频</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-row>
        <el-form :model="editForm" :rules="editRules" ref="editRef" label-width="100px" class="edit-form">
          <el-form-item label="视频标题" prop="title">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item label="上传视频" prop="content">
            <upload :url="editForm.content" :filed="'content'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
          </el-form-item>
          <el-form-item label="视频状态" prop="status">
            <el-radio-group v-model="editForm.status">
              <el-radio :label="1" >待审核</el-radio>
              <el-radio :label="2" >正常</el-radio>
              <el-radio :label="3">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('editRef')">立即修改</el-button>
            <el-button @click="backUrl">返回</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'VideoEdit',
  components: {
    Upload
  },
  data () {
    return {
      editForm: {
        hospital_id: 0,
        area_id: 0,
        nurse_id: 0,
        title: '',
        content: '',
        status: 1
      },
      editRules: {
        title: [
          { required: true, message: '请输入视频标题', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在 5 到 30 个字符', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请上传视频内容', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择视频状态', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getSingleData()
  },
  methods: {
    async getSingleData () {
      const { data: res } = await this.$http.get('/video/' + this.$route.params.id)
      if (res.meta.status === 200) {
        this.editForm.hospital_id = res.data.hospital_id
        this.editForm.area_id = res.data.area_id
        this.editForm.nurse_id = res.data.nurse_id
        this.editForm.title = res.data.title
        this.editForm.content = res.data.content
        this.editForm.status = res.data.status
      }
    },
    backUrl () {
      this.$router.push('/dashboard/video')
    },
    // 提交表单
    async submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/video/' + this.$route.params.id, this.editForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            await this.$router.push('/dashboard/video')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.editForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  padding: 60px;
}
</style>
